import { render, staticRenderFns } from "./newsFolderForm.vue?vue&type=template&id=de7e2628&scoped=true"
import script from "./newsFolderForm.vue?vue&type=script&lang=js"
export * from "./newsFolderForm.vue?vue&type=script&lang=js"
import style0 from "./newsFolderForm.vue?vue&type=style&index=0&id=de7e2628&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "de7e2628",
  null
  
)

export default component.exports