const state = () => ({
    knowledgeDetail:{
        status:"UNPUBLISHED",
        name:"",
        dirName:"",
        currentVersion:"",
        previousPubVersion:{
            state:"",
            no:""
        },
        pendingApproval:false,
        managePermission:[]
    },
    showDownloadBtn:false,
    textWatermarkStr:""
})

const getters = {
    getKnowledge: (state) => {
        return state.knowledgeDetail;
    },
    getDownLoadBtn:(state) => {
        return state.showDownloadBtn;
    },
    getTextWatermarkStr:(state) => {
        return state.textWatermarkStr;
    }
}

const actions = {
    updateKnowledge({ commit }, knowledge) {
        commit("setKnowledge", knowledge);
    },
    updateDownLoadBtn({ commit }, downLoad) {
        commit("setDownLoadBtn", downLoad);
    },
    updateTextWatermarkStr({ commit }, textValue) {
        commit("setTextWatermarkStr", textValue);
    },
}

const mutations = {
    setKnowledge(state, knowledge) {
        state.knowledgeDetail = knowledge;
    },
    setDownLoadBtn(state,downLoadBtn){
        state.showDownloadBtn = downLoadBtn;
    },
    setTextWatermarkStr(state,textValue){
        state.textWatermarkStr = textValue;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}