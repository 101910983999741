import { render, staticRenderFns } from "./FeiShuBindCom.vue?vue&type=template&id=67c27b79&scoped=true"
import script from "./FeiShuBindCom.vue?vue&type=script&lang=js"
export * from "./FeiShuBindCom.vue?vue&type=script&lang=js"
import style0 from "./FeiShuBindCom.vue?vue&type=style&index=0&id=67c27b79&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67c27b79",
  null
  
)

export default component.exports