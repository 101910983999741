<template>
  <div id="KnowledgePreview" :class="(knowledgeDetail.fileSuffix === 'xls' || knowledgeDetail.fileSuffix === 'xlsx') ? 'xlsKnowledgeHeight' : ''">
    <intelligent-summary
        v-show="showSummary"
        :knowledgeId="knowledgeDetail.knowledgeId"
        @closeSummary="closeSummary"
    ></intelligent-summary>
    <vue-excel :src="src" v-if="fileType === 'EXCEL'"></vue-excel>
    <vue-pdf :src="src" v-else-if="fileType === 'PDF'"></vue-pdf>
    <vue-doc :src="src" v-else-if="fileType === 'DOC'"></vue-doc>
    <vue-txt :src="src" v-else-if="fileType === 'TXT'"></vue-txt>
    <file-preview
        v-else
        ref="filePreview"
        :url="src"
        :fileType="fileType"
        :hiddenToolbar="true">
    </file-preview>
    <div class="reviewContainer" id="reviewContainer">
      <div class="noOneLikeStatus"
           :class="comment.likeNumber > 0 ? 'likeStatus1' : 'likeStatus2'"
      >
        <div class="likeBtn" @click="likeKnowledge">
          <i class="iconfont guoran-dianzan"></i>
        </div>
        <div class="likeText" :class="$i18n.locale === 'en' ? 'likeTextEn' : ''">
          <span v-if="comment.likeNumber == 0">{{ $t('versionManage.emptyLike') }}</span>
          <span v-else>{{comment.likeNumber}}{{ $t('versionManage.numLike') }}</span>
        </div>
        <div class="likeList">
          <div
               v-for="(item,index) in comment.likeList"
               :key="index"
          >
            <el-tooltip placement="top" effect="dark">
              <div slot="content">
                {{ item.name }}
              </div>
              <el-avatar :size="24" :src="item.avatar" v-if="item.avatar"></el-avatar>
              <span v-else-if="item.name" class="likeItem">{{(item.name).substr(-2)}}</span>
            </el-tooltip>
          </div>
        </div>
      </div>
      <div class="commentList" v-if="comment.list.length">
        <div class="header">{{ $t('versionManage.fullTextComments') }}</div>
        <comment-com
            :commentList="comment.list"
            :replyPageIndex="replyPageIndex"
            :replyPageSize="replyPageSize"
            :clientUser="clientUser"
            @replyComment="replyComment"
            @updateComment="updateComment"
            @deleteReply="deleteReply"
            @loadMore="loadMore"
        ></comment-com>
        <div class="loadMoreComment"
             v-if="totalCount - ((pageIndex + 1) * pageSize) > 0"
             @click="loadMoreComment"
        >
          <span>{{ $t('versionManage.loadMore') }}</span>
          <i class="el-icon-arrow-down"></i>
        </div>
      </div>
      <ckeditor-input
          @sendComment="sendComment"
          @cancelInput="cancelInput"
          ref="ckeditorParentInput"
          :knowledgeComment="true"
          :parentCommentId="parentCommentId"
          :replyItem="replyItem"
      ></ckeditor-input>
    </div>
    <div class="floating" @click="scrollView" :class="isShowHistoryVersion ? 'floating-1' : 'floating-2'">
      <div class="ksCOFC">
        <i class="iconfont " :class="goTop ? 'guoran-shangyi' : 'guoran-tongyichicun-14-06-pingjiaguanli'"></i>
      </div>
    </div>
  </div>
</template>

<script>
import filePreview from "@/components/FilePreview";
import CommentCom from "@/components/knowledgePreview/comment";
import { requestUrl } from "@/http/requestUrl";
import VueExcel from "@/components/preview/excel/vueExcel";
import VuePdf from "@/components/preview/pdf/vuePdf";
import VueDoc from "@/components/preview/doc/vueDoc";
import VueTxt from "@/components/preview/txt/vueTxt";
import CkeditorInput from "@/components/knowledgePreview/ckeditorInput";
import IntelligentSummary from "@/components/intelligentSummary";

export default {
  name: "KnowledgePreview",
  data(){
    return{
      previewUrl:"",
      comment:{
        likeNumber:0,
        list:[],
        likeList:[],
        like:false
      },
      goTop:false,
      observer:null,
      replyItem:null,
      pageIndex:0,
      pageSize:20,
      replyPageIndex:{},
      replyPageSize:5,
      commentId:null,
      totalCount:0,
      parentCommentId:"",
      clientUser:null,
      showSummary:true
    }
  },
  components:{
    IntelligentSummary,
    CkeditorInput,
    VueTxt,
    VueDoc,
    VuePdf,
    VueExcel,
    CommentCom,
    filePreview
  },
  props:{
    knowledgeDetail:{
      type:Object,
      default(){
        return {}
      }
    },
    src:{
      type:String,
      default:""
    },
    fileType:{
      type:String,
      default:""
    },
    isShowHistoryVersion:{
      type:Boolean,
      default:false
    }
  },
  methods:{
    getCommentList(commentId){
      let url = requestUrl.comment.commentList;
      let obj = {
        "knowledgeId":this.knowledgeDetail.knowledgeId,
        "commentId": commentId,
        "pageIndex": this.pageIndex,
        "pageSize": this.pageSize,
        "replyPageIndex":0,
        "replyPageSize": this.replyPageSize
      }
      if (commentId){
        obj.knowledgeId = null;
        if (!this.replyPageIndex[commentId]){
          this.replyPageIndex[commentId] = 0;
        }
        obj.replyPageIndex = this.replyPageIndex[commentId];
      }
      this.$http.post(url,obj).then(res => {
        if (res.data.code == 0 && res.data.data){
          let rows = res.data.data.rows;
          let data = rows.map(da => {
            this.$set(da,'view',true);
            this.$set(da,'editing',false);
            if (!commentId){
              if (!this.replyPageIndex[da.commentId]){
                this.replyPageIndex[da.commentId] = 0;
              }
              this.$set(da,'replyPageIndex',this.replyPageIndex[da.commentId])
            }
            if (da.comments && da.comments.rows){
              da.comments.rows = da.comments.rows.map(com => {
                this.$set(com,'view',true);
                this.$set(com,'editing',false);
                return com
              })
            }
            return da
          })
          // this.comment.list = [];
          // this.comment.list = data;
          if (commentId){
            this.comment.list.forEach(item => {
              this.$set(item,'replyPageIndex',this.replyPageIndex[commentId])
              if (item.commentId === commentId){
                console.log('totalCount',res.data.data.totalCount)
                if (!item.comments){
                  this.$set(item,'comments',{
                    rows:[],
                    totalCount:0
                  })
                }
                item.comments.totalCount = res.data.data.totalCount;
                if (this.replyPageIndex[commentId] == 0){
                  item.comments.rows = data;
                } else {
                  //添加回复时若超出每页数据时会默认push到最后，所以分页加载时需要将数据过滤掉
                  let ids = item.comments.rows.map(row => row.commentId)
                  let newData = data.filter(item => ids.indexOf(item.commentId) == -1)
                  let count = newData.length - data.length;
                  item.comments.totalCount = item.comments.totalCount - count;
                  item.comments.rows = item.comments.rows.concat(newData);
                  // item.comments.rows = item.comments.rows.concat(data);
                }
              }
            })
          } else {
            this.totalCount = res.data.data.totalCount;
            if (this.pageIndex === 0){
              this.comment.list = [];
            }
            this.comment.list = this.comment.list.concat(data);
            // this.$set(this.comment,'list',data);
          }
          console.log('data',this.comment.list)
        }
      })
    },
    //发送评论
    sendComment(htmlContent){
      const reg = /th:text="(.*?)"/g;
      const regex = new RegExp('<span' + '[^>]*>(.*?)<\/' + 'span>', 'g');
      const arr = [];
      let matched = null;
      let nameMatched = null;
      while ((matched = reg.exec(htmlContent)) !== null) {
        let obj ={
          memberId:matched[1],
          mainId:sessionStorage.getItem('_mainId'),
          uid:""
        }
        if ((nameMatched = regex.exec(htmlContent)) !== null){
          if (nameMatched[1]){
            obj.name = nameMatched[1].replace('@',"")
          }
        }
        arr.push(obj);
      }
      let url = requestUrl.comment.saveComment;
      let obj = {
        htmlContent:htmlContent,
        atUserList:arr
      }
      //replyItem有值表示是回复某个评论或回复
      if (this.replyItem){
        url = requestUrl.comment.replyComment
        obj.commentId = this.replyItem.commentId;
        this.replyPageIndex[obj.commentId] = 0;
      } else {
        obj.knowledgeId = this.knowledgeDetail.knowledgeId
      }
      this.$http.post(url,obj).then(res => {
        if (res.data.code == 0){
          this.$message({
            message:"评论成功",
            type:"success"
          })
          if (this.replyItem){
            //以下处理是二级评论过多的时候直接push到该评论的回复的最后，防止分页导致数据未显示
            let obj = {
              commentId : res.data.data.commentId,
              commentatorName:sessionStorage.getItem('realname'),
              commentatorUid:sessionStorage.getItem('_uid'),
              htmlContent,
              createdAt:new Date().format('yyyy-MM-dd hh:mm:ss'),
              updatedAt:new Date().format('yyyy-MM-dd hh:mm:ss'),
              comments:null,
              knowledgeId:"",
              like: false,
              likeCount: 0,
              likes: [],
              parentId:this.replyItem.commentId,
              parentUserName:this.replyItem.commentatorName,
              view:true,
              commentatorAvatar:sessionStorage.getItem('profilePhoto')
            }
            let parentId = "";
            if (this.parentCommentId){
              parentId = this.parentCommentId;
            } else {
              parentId = this.replyItem.commentId
            }
            this.comment.list.forEach(item => {
              if (item.commentId === parentId){
                if (!item.comments){
                  this.$set(item,'comments',{
                    rows:[],
                    totalCount:0
                  })
                }
                item.comments.rows.push(obj)
                item.comments.totalCount += 1;
              }
            })
          } else {
            this.getCommentList('')
          }
          this.replyItem = null;
          this.parentCommentId = "";
          this.$refs.ckeditorParentInput.htmlContent = '';
        }
      })
    },
    updateComment(htmlContent,item){
      const reg = /th:text="(.*?)"/g;
      const regex = new RegExp('<span' + '[^>]*>(.*?)<\/' + 'span>', 'g');
      const arr = [];
      let matched = null;
      let nameMatched = null;
      while ((matched = reg.exec(htmlContent)) !== null) {
        let obj ={
          memberId:matched[1],
          mainId:sessionStorage.getItem('_mainId'),
          uid:""
        }
        if ((nameMatched = regex.exec(htmlContent)) !== null){
          if (nameMatched[1]){
            obj.name = nameMatched[1].replace('@',"")
          }
        }
        arr.push(obj);
      }
      let url = requestUrl.comment.updateReply;
      let obj = {
        htmlContent:htmlContent,
        commentId:item.commentId,
        atUserList:arr
      }
      this.$http.put(url,obj).then(res => {
        if (res.data.code == 0){
          this.$message({
            message:"编辑评论成功",
            type:"success"
          })
          item.htmlContent = htmlContent
          // this.getCommentList();
          item.editing = false;
          item.view = true;
          item.updatedAt = new Date().format('yyyy-MM-dd hh:mm:ss')
        }
      })
    },
    //删除评论/回复
    deleteReply(item,parentCommentId){
      let url = requestUrl.comment.removeComment + item.commentId;
      this.$http.delete(url).then(res => {
        if (res.data.code == 0){
          this.$message({
            message:"删除成功",
            type:"success",
          })
          this.replyPageIndex[parentCommentId] = 0;
          this.pageIndex = 0;
          this.getCommentList();
        }
      })
    },
    cancelInput(){
      this.replyItem = null;
      this.parentCommentId = "";
      this.$refs.ckeditorParentInput.htmlContent = '';
    },
    //回复评论
    replyComment(item,parentCommentId){
      console.log('item',item,parentCommentId)
      this.replyItem = item;
      this.parentCommentId = parentCommentId;
      setTimeout(() => {
        this.$refs.ckeditorParentInput.currentEditor.editing.view.focus();
        let target = document.getElementById('ckeditorCom');
        let parent = document.getElementById('now-version');
        parent.scrollTo(0, target.offsetTop - parent.offsetTop);
      },50)
    },
    //点赞知识
    likeKnowledge(){
      let url = requestUrl.comment.likeKnowledge + this.knowledgeDetail.knowledgeId;
      this.$http.put(url).then(res => {
        console.log('res',res)
        if (res.data.code == 0){
          if (this.comment.like){
            this.$message({
              message:this.$t('versionManage.cancelLike'),
              duration:2000,
              type:'success'
            })
          } else {
            this.$message({
              message:this.$t('versionManage.like'),
              duration:2000,
              type:'success'
            })
          }
        }
        this.getKnowledgeLikeList();
      })
    },
    getKnowledgeLikeList(){
      let url = requestUrl.comment.likeKnowledgeList + this.knowledgeDetail.knowledgeId;
      this.$http.get(url).then(res => {
        if (res.data.code == 0 && res.data.data){
          this.comment.likeNumber = res.data.data.likeCount;
          this.comment.likeList = res.data.data.likes;
          this.comment.like = res.data.data.like
        }
      })
    },
    observerFun(){
      // const option = {
      //   threshold:1.0,
      //   root:document.querySelector('#KnowledgePreview')
      // }
      this.observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          console.log('entry',entry.isIntersecting)
          if (entry.isIntersecting){
            this.goTop = true;
          } else {
            this.goTop = false;
          }
        });
      });
      let div = document.getElementById('reviewContainer')
      this.observer.observe(div);
    },
    scrollView(){
      let target = document.getElementById('reviewContainer');
      let parent = document.getElementById('now-version');
      if (this.goTop){
        parent.scrollTo(0, 0);
      } else {
        parent.scrollTo(0, target.offsetTop - parent.offsetTop);
      }
    },
    //加载更多二级评论
    loadMore(item,parentCommentId){
      console.log('loadMore',item,parentCommentId)
      this.replyPageIndex[parentCommentId] += 1;
      this.getCommentList(parentCommentId);
    },
    //加载更多评论
    loadMoreComment(){
      this.pageIndex += 1;
      this.getCommentList();
    },
    getClientUser(){
      let url = "/knowledge-api/sso/auth/client-user";
      this.$http.get(url).then(res => {
        console.log('getClientUser',res)
        if (res.data.code == 0 && res.data.data){
          this.clientUser = res.data.data
        }
      })
    },
    closeSummary(){
      this.showSummary = false;
      this.$emit('closeSummary')
    }
  },
  mounted() {
    this.documentId = this.$route.query.id;
    this.getCommentList();
    this.getKnowledgeLikeList();
    this.getClientUser()
    this.$nextTick(() => {
      this.observerFun();
    })
  }
};
</script>

<style scoped lang="less">
#KnowledgePreview{
  //height: calc(100% - 41px);
  //height: 100%;
  //overflow-y: scroll;
  scroll-behavior: smooth;
  //overflow: hidden;
  max-width: 820px;
  margin: 0 auto;
  padding: 0 20px;
  .reviewContainer{
    //padding-right: 10px;
    margin-top: 20px;
    .noOneLikeStatus{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .likeBtn{
        flex: none;
        width: 43px;
        height: 43px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        cursor: pointer;
        i{
          color: #ffffff;
          font-size: 26px;
        }
      }
      .likeText{
        text-align: center;
        position: relative;

        &::after {
          position: absolute;
          top: 52%;
          content: '';
          width: 40%;
          height: 1px;
          right: -55px;
        }

        &::before {
          position: absolute;
          left: -55px;
          top: 52%;
          content: '';
          width: 40%;
          height: 1px;
        }
      }
      .likeTextEn{
        &::after{
          right: -90px;
        }
        &::before{
          left: -90px;
        }
      }
    }
    .likeStatus1{
      .likeBtn{
        background: #D2DEFF;
      }
      .likeText{
        color: #366AFF;
        &::after{
          background-color: #D6DFFF;
        }
        &::before{
          background-color: #D6DFFF;
        }
      }
    }
    .likeStatus2{
      .likeBtn{
        background: #E1E6ED;
      }
      .likeText{
        color: #A9B3C6;
        &::after{
          background-color: #E6EDFF;
        }
        &::before{
          background-color: #E6EDFF;
        }
      }
    }
    .likeList{
      display: flex;
      flex-wrap: wrap;
      margin-top: 10px;
      .likeItem{
        flex: none;
        width: 26px;
        height: 26px;
        background: #366AFF;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        margin-right: 6px;
        font-size: 12px;
      }
      /deep/.el-avatar{
        margin-right: 6px;
      }
    }
    .commentList{
      margin-top: 10px;
      .header{
        font-size: 18px;
        color: #1f2329;
        font-weight: 500;
        padding-bottom: 12px;
        border-bottom: 1px solid #E0E6F7;
        font-family: "Microsoft YaHei";
      }
      .setReplayItemBorder{
        border: 1px dashed #E0E6F7;
      }
      .loadMoreComment{
        margin: 20px 0;
        padding-left: 10px;
        color: #366aff;
        cursor: pointer;
      }
    }
  }
  .floating{
    height: 36px;
    width: 36px;
    position: absolute;
    cursor: pointer;
    z-index: 61;
    bottom: 20px;
    .ksCOFC{
      height: 36px;
      width: 36px;
      border: 1px solid #dee0e3;
      border-radius: 50%;
      box-shadow: 0px 0px 18px 0px rgba(29, 55, 129, 0.07);
      background-color: #ffffff;
      pointer-events: all;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .floating-1{
    right: 60px;
  }
  .floating-2{
    right: 450px;
  }
}
.xlsKnowledgeHeight{
  height: 100%;
  /deep/.file_pre{
    height: 80% !important;
    background-color: #ffffff;
    border-radius: 5px;
  }
}
</style>