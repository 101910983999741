<template>
  <div class="vue-office-pdf">
    <div class="vue-office-pdf-main" id="vue-office-pdf-main" ref="rootRef"></div>
  </div>
</template>

<script>
import _ from "lodash";

const pdfjsLib = window['pdfjsLib']
if(pdfjsLib) {
  pdfjsLib.GlobalWorkerOptions.workerSrc = window['pdfjs-dist/build/pdf.worker']
}
const { TextLayerBuilder } = window['pdfjs-dist/web/pdf_viewer']
import waterMark from "@/utils/common";
import { mapGetters } from 'vuex'
export default {
  name: "vuePdf",
  data(){
    return{
      loadingTask:null,
      transport:null,
      numPages:0,
      pdfDoc:null,
      lazySize:3
    }
  },
  props:{
    src:{
      type:String,
      default:""
    },
  },
  computed:{
    ...mapGetters('knowledge', {
      textWatermarkStr: 'getTextWatermarkStr'
    }),
  },
  methods:{
    async init(){
      //将文件转换成blob解决pdfjs引起浏览器崩溃的问题测试
      this.loadingTask = pdfjsLib.getDocument(this.src);
      this.loadingTask.promise.then(async pdf => {
        // 获取PDF的第一页
        this.pdfDoc = pdf;
        this.transport = pdf._transport;
        // this.numPages = pdf._transport.numPages;
        this.lazySize = pdf._transport.numPages;
        this.numPages = Math.min(pdf._transport.numPages, this.lazySize);
        setTimeout(() => {
          this._renderPage(1);
        })
      })
    },
    async _renderPage(num){
      await this.pdfDoc.getPage(num).then(async page => {
        let pageMain = document.getElementById('vue-office-pdf-main');
        const pageDom = document.createElement('div');
        let id = 'pdf-canvas-' + num;
        pageDom.setAttribute('id', id);
        pageDom.setAttribute('class', 'pdf-page');

        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        const viewport = page.getViewport({ scale: 2 })
        let outputScale = 2;

        canvas.width = Math.floor(viewport.width * outputScale);
        canvas.height = Math.floor(viewport.height * outputScale);

        let domWidth = Math.floor(viewport.width);
        let domHeight = Math.floor(viewport.height);

        let wrapperWidth = document.getElementById('vue-office-pdf-main').clientWidth - 20

        if (domWidth > wrapperWidth){
          let scale = wrapperWidth / domWidth;
          domWidth = Math.floor(wrapperWidth);
          domHeight = Math.floor(viewport.height * scale);
        }
        // 根据缩放比例调整canvas大小

        canvas.style.width = domWidth + 'px';
        canvas.style.height = domHeight + 'px';

        pageDom.style.width = domWidth + 'px';
        pageDom.style.height = domHeight + 'px';
        pageDom.style.position = 'relative';
        pageDom.style.marginBottom = '10px'
        pageDom.style.marginLeft = 'auto';
        pageDom.style.marginRight = 'auto';
        pageDom.style.backgroundColor = "#ffffff";
        pageDom.style.boxShadow = "0px 0px 18px 0px rgba(29, 55, 129, 0.07)"
        pageDom.appendChild(canvas);

        const transform = outputScale !== 1
            ? [outputScale, 0, 0, outputScale, 0, 0]
            : null;

        await page.render({
          canvasContext: context,
          transform:transform,
          viewport:viewport
        }).promise.then(() => {
          return page.getTextContent()
        }).then(async (textContent) => {
          // // 渲染PDF页面到canvas上

          var textlayerDiv = document.createElement("div");
          textlayerDiv.setAttribute('class', 'textLayer');
          let textlayer = new TextLayerBuilder({
            textLayerDiv: textlayerDiv,
            viewport: viewport,
          });

          textlayer.setTextContent(textContent);
          textlayer.render();
          pageDom.appendChild(textlayerDiv);

          pageMain.appendChild(pageDom);
          if (this.textWatermarkStr){
            waterMark.newInitWaterMark(id,this.textWatermarkStr)
          }
        });
        // pageMain.appendChild(pageDom);
        if (this.numPages > num){
          this._renderPage(num + 1)
        }
      })
    },
    onScrollPdf: _.debounce(function (e, that) {
      const { scrollTop, scrollHeight, clientHeight } = e.target;
      if (scrollTop + clientHeight >= scrollHeight) {
        if (that.numPages >= that.pdfDoc._transport.numPages) {
          return;
        }
        let oldNum = that.numPages;
        that.numPages = Math.min(that.pdfDoc._transport.numPages, oldNum + that.lazySize);
        if (that.numPages > oldNum) {
          that._renderPage(oldNum + 1);
        }
      }
    }, 200),
  },
  mounted() {
    this.init();
    document.getElementById('KnowledgePreview').addEventListener('scroll',(e) => {
      this.onScrollPdf(e,this)
    })
  },
  destroyed() {
    this.pdfDoc.destroy()
  }
};
</script>

<style scoped lang="less">
.vue-office-pdf{
  //height: 100%;
  .vue-office-pdf-main{
    //height: calc(100% - 20px);
    //overflow-y: scroll;
    //background: #ededf0;
    //background: #ffffff;
    //padding: 10px 0;
    position: relative;
    .pdf-page{
      background: #ffffff;
      box-shadow: 0px 0px 18px 0px rgba(29, 55, 129, 0.07)!important;
      /deep/.textLayer{
        opacity: 1;
      }
    }
  }
}
</style>