<template>
  <div class="version-manage">
    <div class="preview-content">
      <!-- 当前版本 -->
      <div id="now-version" :class="['now-version', isShowHistoryVersion ? 'show-history-version' : '']">
        <div class="document-header" v-if="isShowHistoryVersion">
          <div class="document-header-left">
            <img class="file-icon" :src="nowVersionDetail.fileIcon" alt="" srcset="">
            <span class="document-name one-column-ellipsis">{{ nowVersionDetail.name }}</span>
            <div class="now-version-tips" v-if="pageType === 'lookVersion'">{{ $t('versionManage.currentVersion') }}</div>
          </div>
          <div class="version-num" v-if="pageType === 'lookVersion'">V{{ nowVersionDetail.version }}</div>
        </div>
        <knowledge-preview v-if="drawerDocNow" ref="KnowledgePreview" :knowledgeDetail="nowVersionDetail"
          :fileType="fileType" :src="nowVersionPreviewUrl" :isShowHistoryVersion="isShowHistoryVersion"
          @closeSummary="closeSummary"></knowledge-preview>
        <FilePreview v-if="false" ref="filePreview" :url="nowVersionPreviewUrl" :knowledgeItem="nowVersionDetail"
          :fileType="fileType" :hiddenToolbar="true">
        </FilePreview>
      </div>
      <!-- 历史版本 -->
      <div class="history-version" v-if="isShowHistoryVersion">
        <div class="document-header">
          <div class="document-header-left">
            <img width="16px" :src="historyVersionDetail.fileIcon" alt="" srcset="">
            <span class="document-name one-column-ellipsis">{{ historyVersionDetail.name }}</span>
          </div>
          <div class="document-header-right">
            <div class="version-num">V{{ historyVersionDetail.version }}</div>
            <div class="use-history" @click="useThisVersion">{{ $t('versionManage.userVersion') }}</div>
          </div>
        </div>
        <knowledge-preview v-if="drawerDocHistory" ref="KnowledgePreview" :knowledgeDetail="nowVersionDetail"
          :fileType="fileType" :src="historyVersionPreviewUrl" :isShowHistoryVersion="isShowHistoryVersion"
          @closeSummary="closeSummary"></knowledge-preview>
        <FilePreview v-if="false" ref="filePreviewHistory" :url="historyVersionPreviewUrl"
          :knowledgeItem="historyVersionDetail" :fileType="fileType" :hiddenToolbar="true">
        </FilePreview>
      </div>
    </div>
    <div class="knowledgeDetail">
      <div class="knowledgeDetail-content" v-if="isShowKnowledgeDetail">
        <folder-details :isVersionManage="true" :currentNode="nowVersionDetail" @editFolder="editFolder"
          @moveTo="editFolder" @deleteDetail="deleteDetail" @changeSafe="changeSafe" ref="folderDetails">
        </folder-details>
      </div>
      <div class="switch-page-type">
        <el-tooltip class="item" effect="dark" :content="$t('versionManage.infomation')" placement="left-start">
          <div :class="['look-base-set', pageType === 'lookBaseSet' ? 'active' : '']"
            @click.stop="switchPageType('lookBaseSet')">
            <i class="iconfont guoran-xiangqing_shuoming"></i>
          </div>
        </el-tooltip>
        <el-popover placement="left-start" width="270" :visible-arrow="false" popper-class="version-list"
          v-model="visible" trigger="manual">
          <div class="version-item" v-for="item in versioHistoryList" :key="item.id">
            <div class="version-item-day">{{ item.timelineTitle }}</div>
            <div :class="['version-item-content', cellIndex + 1 === item.list.length ? 'version-item-content-last' : '']"
              v-for="(cell, cellIndex) in item.list" :key="cellIndex" @click="chooseHistoryVersion(cell)">
              <div class="version-item-time">{{ cell.time }}</div>
              <span v-if="cell.eventType === 'create'" class="version-item-container">{{$t('versionManage.addKnowledge')}}</span>
              <template v-else>
                <div class="version-item-container" v-if="cell.userName.length > 10">
                  <div class="version-item-content-wrapper">
                    <div class="version-item-box">
                      <span class="version-item-icon-1">{{$t('versionManage.updateKnowledge')}}</span>
                      <span class="version-item-user-name">{{ cell.userName }}</span>
                      <span class="version-item-icon-2">{{$t('versionManage.updateKnowledge')}}</span>
                    </div>
                  </div>
                </div>
                <div v-else class="version-item-container">
                  <span>{{ cell.userName }}{{$t('versionManage.updateKnowledge')}}</span>
                </div>
              </template>
              <div class="version-item-version-text">V{{ cell.version }}</div>
            </div>
          </div>
          <div slot="reference">
            <el-tooltip effect="dark" :content="$t('versionManage.historyVersion')" placement="left-start">
              <div :class="['look-version', pageType === 'lookVersion' ? 'active' : '']"
                @click.stop="switchPageType('lookVersion')">
                <i class="iconfont guoran-lishibanben"></i>
                <div class="new-dage" v-if="isHaveNewHistory">NEW</div>
              </div>
            </el-tooltip>
          </div>
        </el-popover>
      </div>
    </div>
    <div class="folder-management">
      <folder-management :corpId="corpId" :currentNode="nowVersionDetail" :isVersionManage="true" ref="folderManagement"
        @getKnowledge="getKnowledge">
      </folder-management>
    </div>
    <delete-dialog @closeEvent="deleteFileDialog = false" v-if="deleteFileDialog">
      <div slot="popup-container" class="popup-container">
        <div class="delete-title">删除文件</div>
        <div class="prompt-text">
          是否确认删除该文件？
        </div>
      </div>
      <div slot="dialog-footer">
        <el-button @click="deleteFileDialog = false" round size="small" plain class="cancel-btn">取 消</el-button>
        <el-button @click="deleteFile" style="background-color: #FE5965 ;color: white;border:none;" size="small"
          class="confirm-btn">删 除
        </el-button>
      </div>
    </delete-dialog>
    <delete-dialog @closeEvent="revokeApprovalDialog = false" v-if="revokeApprovalDialog">
      <div slot="popup-container" class="popup-container">
        <div class="delete-title">撤销审批</div>
        <div class="prompt-text">
          已提交 {{ revokeApprovalType | typeFilterTitle }} 审批
        </div>
      </div>
      <div slot="dialog-footer">
        <el-button @click="revokeApprovalDialog = false" round size="small" plain class="cancel-btn">取 消</el-button>
        <el-button @click="revokeApproval" style="background-color: #FE5965 ;color: white;border:none;" size="small"
          class="confirm-btn">撤销
        </el-button>
      </div>
    </delete-dialog>
  </div>
</template>

<script>
import FolderDetails from "@/views/knowledgeManagement/folderDetails";
import FolderManagement from "@/views/knowledgeManagement/folderManagement";
import FilePreview from "@/components/FilePreview";
import formatDoc from "../../utils/formatDocList";
import { requestUrl } from "@/http/requestUrl";
import KnowledgePreview from "@/components/knowledgePreview/knowledgePreview";
import { getFileType } from "@/components/preview/utils/url";
import { mapGetters, mapActions } from 'vuex';
import DeleteDialog from "@/components/deleteDialog";
export default {
  data () {
    return {
      documentId: '',
      isShowKnowledgeDetail: true,
      pageType: "lookBaseSet", // lookVersion
      corpId: "",
      channel: 0,
      versioHistoryList: [],
      isShowHistoryVersion: false, // 是否展示历史版本
      nowVersionDetail: {}, // 当前版本信息
      nowVersionPreviewUrl: "", // 当前版本的预览地址
      historyVersionDetail: {}, // 历史版本信息
      historyVersionPreviewUrl: "", // 历史版本的预览地址
      drawerDocNow: false,
      drawerDocHistory: false,
      visible: false,
      isHaveNewHistory: false, // 是否有新的历史版本
      fileType: '',
      previewUrl: "",
      deleteFileDialog: false,
      textWatermarkStr: "",
      isDownload: false,
      knowledgeId: "",
      revokeApprovalDialog:false,
      revokeApprovalType:''
    }
  },
  components: {
    DeleteDialog,
    KnowledgePreview,
    FolderDetails,
    FolderManagement,
    FilePreview
  },
  computed: {
    ...mapGetters('knowledge', {
      knowledgeInfo: 'getKnowledge'
    }),
  },
  filters:{
    typeFilterTitle(value) {
      switch (value) {
        case 'KNOWLEDGE_PUBLISH':
            return '发布知识'
        case 'KNOWLEDGE_CANCEL_PUBLISH':
            return '取消发布知识'
        case 'KNOWLEDGE_ARCHIVE':
            return '归档知识'
        case 'KNOWLEDGE_CANCEL_ARCHIVE':
            return '取消归档知识'
        default:
            return '';
      }
    },
  },
  mounted () {
    this.documentId = this.$route.query.id;
    this.knowledgeId = this.$route.query.knowledgeId
    this.getTextWatermark();
    // this.fileType = this.$route.query.fileType;

    this.corpId = sessionStorage.getItem('_corpId')
    this.isShowKnowledgeDetail = true;
    this.selectDocumentVersionHaveNew();
    // this.getKnowledgeDetail();

  },
  beforeDestroy () {
    document.onclick = null;
  },
  methods: {
    ...mapActions('knowledge', [
      'updateKnowledge'
    ]),
    ...mapActions('knowledge', [
      'updateDownLoadBtn'
    ]),
    ...mapActions('knowledge', [
      'updateTextWatermarkStr'
    ]),
    selectDocumentVersionHaveNew () {
      this.$http.get(requestUrl.docVersion.selectDocumentVersionHaveNew + '?docId=' + this.documentId).then(res => {
        if (res.data.code == 0) {
          this.isHaveNewHistory = res.data.data;
        }
      })
    },
    statusFilter(val){
      if (!val || val === 'UNPUBLISHED'){
        return "取消发布"
      } else if (val === 'PUBLISH'){
        return  "发布"
      } else {
        return "归档"
      }
    },
    // 获取当前知识的详情
    getKnowledgeDetail () {
      this.$http.get(requestUrl.knowledgeBase.getKnowledgeBaseById + '?id=' + this.documentId).then(async res => {
        if (res.data.code == 0 && res.data.data) {
          res.data.data.fileIcon = formatDoc.formatFileTypeSrc({ name: res.data.data.name + '.' + res.data.data.fileSuffix })
          this.nowVersionDetail = res.data.data;
          this.historyVersionDetail = res.data.data;
          this.fileType = await getFileType(this.nowVersionDetail.ossPath);
          this.getPreviewContent(this.nowVersionDetail.ossPath, 'nowVersion');
          this.updateKnowledgeInfo(res.data.data);
        }
      })
    },
    updateKnowledgeInfo (data) {
      this.knowledgeInfo.currentVersion = data.version
      if (data.currentVersion) {
        this.knowledgeInfo.status = data.currentVersion.state
        this.knowledgeInfo.pendingApproval = data.currentVersion.pendingApproval
      } else {
            this.knowledgeInfo.status = "UNPUBLISHED"
          }
      if (data.managePermission) {
        let managePermission = JSON.parse(data.managePermission)
        this.knowledgeInfo.managePermission = managePermission.map(res => {
          return Number(res.id)
        });
      } else {
            this.knowledgeInfo.managePermission = null
          }
      this.knowledgeInfo.previousPubVersion = data.previousPubVersion
      console.log('knowledgeInfo', this.knowledgeInfo)
      this.updateKnowledge(this.knowledgeInfo);
    },
    // 查询文档的历史版本
    getVersionHistory () {
      this.$http.get(requestUrl.docVersion.selectDocumentVersionByDocId + '?docId=' + this.documentId).then(res => {
        if (res.data.code == 0) {
          let currentList = formatDoc.formatDocList(res.data.data);
          this.versioHistoryList = currentList;
          this.hiddeenProver()
          // document.addEventListener('click', this.hiddeenProver);
          this.selectDocumentVersionHaveNew();
        } 0
      })
    },
    /**
    * 右侧详情块点击的编辑/移动
    * */
    async editFolder (command, node) {
      let url = requestUrl.knowledgeBase.getKnowledgeBaseById + '?id=' + node.parentId;
      await this.$http.get(url).then(async res => {
        if (res.data.code == 0 && res.data.data) {
          this.$refs.folderManagement.nodeParentData = await res.data.data;
        }
      })
      this.$refs.folderManagement.nodeData = node;
      this.$refs.folderManagement.handleCommand(command, '', '', 'detail-edit');
    },
    changeSafe () {
      this.getTextWatermark();
      this.$nextTick(() => {
        this.drawerDocNow = false;
        setTimeout(() => {
          this.drawerDocNow = true;
        }, 100)
      })
    },
    // 切换基础信息和版本信息
    switchPageType (type) {
      this.pageType = type;
      if (type === 'lookVersion') {
        this.visible = true;
        this.getVersionHistory();
      } else {
        this.drawerDocNow = false;
        this.isShowKnowledgeDetail = true;
        this.isShowHistoryVersion = false;
        setTimeout(() => {
          this.drawerDocNow = true;
        })
      }
    },
    // 编辑知识后,刷新知识详情
    getKnowledge () {
      this.getKnowledgeDetail();
    },
    /**
     * 展示详情更多按钮中删除操作
     * */
    folderDetailHandle (command) {
      if (command === 'del') {
        this.$refs.folderManagement.handleCommand('del')
      }
    },
    // 删除知识成功后
    deleteDetail () {
      this.$router.go(-1);
    },
    chooseHistoryVersion (cell) {
      this.visible = false;
      if (this.nowVersionDetail.version === cell.version) {
        this.messageFn('warning', '已处于当前版本');
        return false
      }
      this.drawerDocHistory = false;
      this.drawerDocNow = false;
      let obj = JSON.parse(JSON.stringify(this.historyVersionDetail))
      this.historyVersionDetail = Object.assign(obj, cell)
      this.isShowHistoryVersion = true;
      this.getPreviewContent(cell.ossPath, 'historyVersion');
      this.isShowKnowledgeDetail = false;
    },
    loadIframe () {
      let iframe = document.getElementsByClassName('preview_iframe')[0];
      console.log(iframe, 'iframe');
      iframe.onload(() => {
        iframe.contentWindow.postMessage({
          type: "hiddenToolbar",
        }, '*')
      })
    },
    // 获取文件的预览地址
    getPreviewContent (ossPath, type) {
      let url = requestUrl.preview.previewUrl + '?isVersionManage=1'
      console.log('fileType', this.fileType)
      if (this.fileType === 'VIDEO' || this.fileType === 'PDF' || this.fileType === 'EXCEL' || this.fileType === 'TXT' || this.fileType === 'DOC') {
        url += '&needEncrypt=false'
      } else {
        url += '&needEncrypt=true'
      }
      this.$http.post(url, { fileInOssPath: ossPath }).then(res => {
        if (res.data.code == 0) {
          if (type === 'nowVersion') {
            this.nowVersionPreviewUrl = res.data.data;
            this.previewUrl = res.data.data;
            this.drawerDocNow = true;
            this.drawerDocHistory = false;
            this.$nextTick(() => {
              if (this.fileType === 'HTML') {
                this.$refs.KnowledgePreview.$refs.filePreview.getPreviewContent(this.nowVersionDetail.ossPath);
              }
            })
          } else {
            this.historyVersionPreviewUrl = res.data.data;
            this.previewUrl = res.data.data;
            this.drawerDocNow = true;
            this.drawerDocHistory = true;
            this.$nextTick(() => {
              if (this.fileType === 'HTML') {
                this.$refs.KnowledgePreview.$refs.filePreview.getPreviewContent(this.historyVersionDetail.ossPath);
                // this.$refs.filePreviewHistory.getPreviewContent(this.historyVersionDetail.ossPath);
              }
            })
          }
        }
      })
    },
    // 使用此版本
    useThisVersion () {
      this.$http.get(requestUrl.docVersion.useDocumentVersionByDocId + '?docId=' + this.documentId + '&version=' + this.historyVersionDetail.version).then(res => {
        if (res.data.code == 0) {
          this.messageFn('success', this.$t('versionManage.updateDoc'));
          this.getKnowledgeDetail();
          this.isShowHistoryVersion = false;
        }
      })
    },
    // 点击prover元素时绑定click事件,以此来实现点击除prover元素之外的区域隐藏prover
    hiddeenProver () {
      document.querySelector(".version-list").onclick = (e) => {
        console.log(e);
        e.stopPropagation ? e.stopPropagation() : e.cancelBubble = true;
        if (e.target.className.indexOf('version-item') == -1) {
          this.visible = true;
        }
      }
      document.onclick = () => {
        this.visible = false;
      }
    },
    // 获取是否存在发布，归档，取消发布时需要审批的自动化节点
    getAutomatedNode (eventType) {
      return new Promise((reslove, resject) => {
        this.$http(requestUrl.automation.getNodeCheck + '?type=APPROVAL' + '&eventType=' + eventType + '&corpKnowledgeId=' + this.documentId).then(res => {
          reslove(res.data)
        }).catch(() => {
          resject(false)
        })
      })
    },
    // 审批校验
    verifyIfAutomationIsTriggered (type) {
      this.$emit('getApprovalLoading', true)
      // 该操作需要审批，是否发布/归档/取消发布/取消归档？
      let typeObject = {
        'PUBLISHED': {
          label: '发布',
          fn: 'publishKnowledge',
          eventType: 'KNOWLEDGE_PUBLISH'
        },
        'UNPUBLISHED': {
          label: '取消发布',
          fn: 'unPublishKnowledge',
          eventType: 'KNOWLEDGE_CANCEL_PUBLISH'
        },
        'ARCHIVE': {
          label: '归档',
          fn: 'archiveKnowledge',
          eventType: 'KNOWLEDGE_ARCHIVE'
        },
        'UNARCHIVE': {
          label: '取消归档',
          fn: 'archiveKnowledge',
          eventType: 'KNOWLEDGE_CANCEL_ARCHIVE'
        },
      }
      this.getAutomatedNode(typeObject[type].eventType).then(flag => {
        this.$emit('getApprovalLoading', false)
        if (flag) {
          let str = '该操作需要审批，是否' + typeObject[type].label
          this.$emit('sureApprovalStatus', true)
          this.$confirm(str + '?', '审批', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.$emit('sureApprovalStatus', false)
            this[typeObject[type].fn](true, type)
          }).catch(() => {
            this.$emit('sureApprovalStatus', false)
          });
        } else {
          this[typeObject[type].fn](false, type)
        }
      }).catch(() => {
        this.$message.error('操作异常，请稍后再试！')
        this.$emit('getApprovalLoading', false)
      })
    },
    revokeApproval() {
      let url = requestUrl.automation.cancelApproval + `?corpKnowledgeId=${this.nowVersionDetail.id}&version=${this.nowVersionDetail.version}&check=false`
      this.$http.put(url).then(res => {
        if (res.data.code == 0) {
          this.$message.success("取消审批成功")
          this.revokeApprovalDialog = false
          this.getKnowledgeDetail()
          this.$emit('sureApprovalStatus', false)
        }
      })
    },
    // 取消审批，获取审批详情
    cancelApproval () {
      let url = requestUrl.automation.cancelApproval + `?corpKnowledgeId=${this.nowVersionDetail.id}&version=${this.nowVersionDetail.version}&check=true`
      this.$http.put(url).then(res => {
        if (res.data.code == 0) {
          this.revokeApprovalType = res.data.data.type
          this.revokeApprovalDialog = true
        }
      })
    },
    //发布知识
    saveOrPublish (type) {
      this.verifyIfAutomationIsTriggered(type)
    },
    publishKnowledge (needApproval) {
      let url = requestUrl.knowledgeBase.publishKnowledge + '?publish=true';
      let id = this.$route.query.id
      // 需要校验审批流程，点击审批确定后直接触发接口
      if (needApproval) {
        url = url + '&triggerAutomationFlow=true'
        this.publishKnowledgeAxios(url, id, needApproval)
        return
      } else {
        this.$confirm(this.$t('versionManage.publishTip') + '?', this.$t('status.release'), {
          confirmButtonText: this.$t('common.confirm'),
          cancelButtonText: this.$t('common.cancel'),
          type: 'warning'
        }).then(() => {
          this.publishKnowledgeAxios(url, id, needApproval)
          this.$emit('sureApprovalStatus', false)
        }).catch(() => {
          this.$emit('sureApprovalStatus', false)
        });
      }
    },
    publishKnowledgeAxios (url, id, needApproval) {
      this.$http.post(url, id, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(res => {
        console.log('res', res)
        if (res.data.code == 0) {
          this.$message({
            message: needApproval ? '提交审批成功，请等待审批通过' : "知识发布成功",
            type: "success"
          })
          // this.updateKnowledgeInfo(res.data.data);
          this.getKnowledgeDetail()
        }
        this.$emit('sureApprovalStatus', false)
      })
    },
    //取消发布知识
    unPublishKnowledge (needApproval) {
      let url = requestUrl.knowledgeBase.publishKnowledge + '?publish=false';
      let id = this.$route.query.id
      // 需要校验审批流程，点击审批确定后直接触发接口
      if (needApproval) {
        url = url + '&triggerAutomationFlow=true'
        this.unPublishKnowledgeAxios(url, id, needApproval)
      } else {
        this.$confirm('是否取消发布知识' + '?', '取消发布', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.unPublishKnowledgeAxios(url, id, needApproval)
          this.$emit('sureApprovalStatus', false)
        }).catch(() => {
          this.$emit('sureApprovalStatus', false)
        });
      }
    },
    unPublishKnowledgeAxios (url, id, needApproval) {
      this.$http.post(url, id, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(res => {
        console.log('res', res)
        if (res.data.code == 0) {
          this.$message({
            message: needApproval ? '提交审批成功，请等待审批通过' : "知识取消发布成功",
            type: "success"
          })
          this.getKnowledgeDetail()
          // this.updateKnowledgeInfo(res.data.data);
        }
        this.$emit('sureApprovalStatus', false)
      })
    },
    // 删除知识
    deleteNews () {
      console.log('删除')
      this.deleteFileDialog = true;
    },
    //知识归档或者取消归档
    archiveKnowledge (needApproval, type) {
      let url = requestUrl.knowledgeBase.archiveKnowledge;
      let message = "";
      let popupTips = ""
      if (type === 'ARCHIVE') {
        url += '?archive=true';
        message = "知识归档成功";
        popupTips = "是否归档知识"
      } else {
        url += '?archive=false'
        message = "知识取消归档成功";
        popupTips = "是否取消归档知识"
      }
      let id = this.$route.query.id
      if (needApproval) {
        url = url + '&triggerAutomationFlow=true'
        message = '提交审批成功，请等待审批通过'
        this.archiveKnowledgeAxios(url, id, message)
      } else {
        this.$confirm(popupTips + '?', type === 'ARCHIVE' ? '归档' : '取消归档', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.archiveKnowledgeAxios(url, id, message)
          this.$emit('sureApprovalStatus', false)
        }).catch(() => {
          this.$emit('sureApprovalStatus', false)
        });
      }
    },
    archiveKnowledgeAxios (url, id, message) {
      this.$http.post(url, id, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(res => {
        this.$message({
          message,
          type: "success"
        })
        if (res.data.code == 0) {
          this.getKnowledgeDetail()
          // this.updateKnowledgeInfo(res.data.data);
        }
        this.$emit('sureApprovalStatus', false)
      })
    },
    deleteFile () {
      let url = requestUrl.knowledgeBase.moveDeleteFolder
      let obj = {
        deleteNodeIds: [this.$route.query.id],
        newParentId: null
      }
      this.$http.delete(url, {
        data: obj
      }).then(res => {
        if (res.data.code == '0') {
          this.$message({
            message: this.$t('versionManage.deleteMsg'),
            duration: 2000,
            type: "success"
          })
          this.deleteDetail();
        }
      })
    },
    editSummary (bool) {
      this.$refs.KnowledgePreview.showSummary = bool;
      if (bool) {
        this.$refs.KnowledgePreview.goTop = true;
        this.$refs.KnowledgePreview.scrollView();
      }
    },
    closeSummary () {
      this.$emit('closeSummary')
    },
    downLoadUrl () {
      let url = this.nowVersionDetail.ossPath
      if (decodeURIComponent(url) != url) {
        url = decodeURIComponent(url)
      }
      let index = url.lastIndexOf("?")
      if (index !== -1) {
        url = url.substr(0, url.lastIndexOf("?"));
      }
      const fileType = url.substring(url.lastIndexOf('.'));
      this.$http.post('/knowledge-api/temporary-certificate/or-origin?expired=10', url, {
        headers: {
          "Content-Type": "application/json",
        }
      }).then(res => {
        if (res.data) {
          let name = (this.nowVersionDetail.name) + fileType
          if (fileType === '.txt') {
            this.$http.get(res.data).then(txtRes => {
              this.downloadTxtFile(txtRes.data, name)
            })
          } else {
            let a = document.createElement('a')
            a.href = res.data
            a.download = (this.nowVersionDetail.name) + fileType
            a.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }))
            this.$message.success({
              message: this.$t('versionManage.downloadSuccess'),
            })
          }
        }
      })
    },
    downloadTxtFile (content, filename) {
      // 创建一个包含 txt 文件内容的 Blob 对象
      const blob = new Blob([content], { type: 'text/plain' });

      // 生成一个 URL
      const url = URL.createObjectURL(blob);

      // 创建一个下载链接
      const link = document.createElement('a');
      link.href = url;
      link.download = filename; // 设置下载文件名
      document.body.appendChild(link);

      // 触发点击下载链接
      link.click();

      // 移除下载链接
      document.body.removeChild(link);

      // 释放 Blob 对象所占用的内存
      URL.revokeObjectURL(url);
    },
    getTextWatermark () {
      let url = requestUrl.textWatermark.getTextWatermark;
      let obj = {
        "knowledgeId": this.knowledgeId,
        "systemType": 2
      }
      this.$http.post(url, obj).then(res => {
        console.log('getTextWatermark', res.data.data)
        if (res.data.data) {
          this.textWatermarkStr = res.data.data.textWatermarkStr;
          this.isDownload = res.data.data.showDownloadBtn;
          this.updateDownLoadBtn(this.isDownload);
          this.updateTextWatermarkStr(this.textWatermarkStr)
        }
      })
    }
  }
}
</script>
<style lang="less">
.el-popover.version-list {
  text-align: inherit;
  max-height: 500px;
  overflow-x: hidden;
  overflow-y: auto;
  background: #FFFFFF;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08);
  border-radius: 14px;
  padding: 0;

  .version-item {
    border-bottom: 1px solid #F4F6FB;
    padding: 15px;

    .version-item-day {
      font-size: 14px;
      color: #A9B3C6;
      margin-bottom: 10px;
    }

    .version-item-content {
      font-size: 14px;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      padding: 4px;
      cursor: pointer;
      position: relative;

      &.version-item-content-last {
        margin-bottom: 0;
      }

      .version-item-version-text {
        margin-left: 10px;
        padding: 0 5px;
        height: 19px;
        background: #E7EAF1;
        border-radius: 2px;
        color: #000000;
        white-space: nowrap;
      }

      .version-item-time {
        margin-right: 6px;
        white-space: nowrap
      }

      &:hover {
        background-color: #CDDCFF;

        .version-item-version-text {
          background-color: #EDF2FF;
        }

        .version-item-box::after {
          background-color: #CDDCFF;
        }
      }

      .version-item-container {
        width: 160px;
      }

      .version-item-content-wrapper {
        display: flex;
        position: relative;
        overflow: hidden;
      }

      .version-item-box {
        font-size: 14px;
        line-height: 20px;
        word-break: break-all;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;

        // height: 20px;
        .version-item-user-name {
          text-align: inherit;
        }
      }

      .version-item-box::before {
        content: '';
        display: block;
        float: right;
        width: 0px;
        height: calc(100% - 18px);
      }

      .version-item-box::after {
        position: absolute;
        content: '';
        display: inline-block;
        width: 100%;
        height: 100%;
        background: #ffffff;
      }

      .version-item-icon-1 {
        height: 16px;
        width: 70px;
        background-size: cover;
        margin-left: 2px;
        float: right;
        clear: both;
        display: block;
        position: relative;
        bottom: 2px;
      }

      .version-item-icon-2 {
        position: relative;
        // display: inline-block;
        height: 16px;
        width: 70px;
        background-size: cover;
        margin-left: 2px;
        transform: translateY(2px);
      }

      .version-item-icon-2::after {
        content: '';
        position: absolute;
        background: #ffffff;
        left: 0px;
        bottom: -40px;
        height: 40px;
        width: 70px;
      }

    }
  }

}
</style>
<style lang="less" scoped>
@import "./versionManage.less";
</style>
